import { React, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import Header from "./Header";
import HeaderLower from "./HeaderLower";
import ContentPopup from "./ContentPopup";
import { Icon } from "@iconify/react";
import { FaCheckCircle } from "react-icons/fa";
// import {assignmentCheck} from "@iconify-icons/zmdi/assignment-check";
import { Navigate, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import LogService from "../services/log.service";
import StorageService from "../services/storage.service";
import { Helmet } from "react-helmet";

function Thankyou(props) {
  const TITLE = "BSSTSI Test Rest | Thank You";
  const navigate = useNavigate();

  var redemptionForm = secureLocalStorage.getItem("UserForm");
  var redemptionObject = redemptionForm ? JSON.parse(redemptionForm) : false;
  if (redemptionObject === false) {
    navigate("/");
  }

  const [website, setWebsite] = useState(window.location.href);
  const [showPopup, setShowPopup] = useState(redemptionObject.popup);
  console.log(redemptionObject.popup);

  useEffect(() => {
    async function logCreate() {
      try {
        await LogService.createLog(website);
      } catch (e) {
        console.log(e);
      }
    }
    logCreate();
  }, [website]);
  // FIXME
  localStorage.removeItem("returnthankyou", true);
  localStorage.removeItem("voucher", "");
  localStorage.removeItem("returnpage", false);
  localStorage.removeItem("userformopen");
  localStorage.removeItem("userform");

  const isVoucher = localStorage.getItem("Thankyou");
  const Voucher = localStorage.getItem("voucher");
  if (isVoucher !== "true" || Voucher === undefined) {
    localStorage.setItem("isVoucher", false);
    return <Navigate to="/" />;
  } else {
    localStorage.setItem("Thankyou", false);
  }

  // StorageService.destroyRedThankStorage();

  // const isVoucher = StorageService.getThankStatus();
  // const Voucher = StorageService.getVoucher();
  // if (
  //   isVoucher !== "true" ||
  //   Voucher === undefined ||
  //   props.history.location.zipcode === "undefined"
  // ) {
  //   StorageService.setVoucherStatus(false);
  //   return <Redirect to="/" />;
  // } else {
  //   StorageService.setThankStatus(false);
  // }
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        <Header />
        {/* <HeaderLower /> */}
        <div className="white-box thank">
          <h1 className="text-center">{process.env.REACT_APP_THANKYOUTITLE}</h1>
          {/* <BsFillCheckCircleFill/> */}
          {/* <Icon icon={BsFillBagCheckFill}/> */}
          {/* <Icon icon={assignmentCheck} /> */}
          <FaCheckCircle />
          <p>Thank You! {redemptionObject.name}</p>
          <ul className="info-data">
            <li key="uniqueId56">
              {redemptionObject.name} {redemptionObject.lname}
            </li>
            <li key="23ff">{redemptionObject.city}</li>
            <li key="uniqueId1">
              {redemptionObject.state} {redemptionObject.address}{" "}
              {redemptionObject.zipcode}
            </li>
          </ul>
          <p>
            Once your order has processed, the link to your rewards card will be
            EMAILED to you by the Virtual Rewards Center.
          </p>
          <p>
            You will need to click that link to finalize your order and select
            your preferred delivery method.
          </p>
          <p>
            Please do not discard your certificate until you receive your reward
            card.
          </p>
          <p>
            If you have any questions, please call{" "}
            <span className="green">1-866-ELITE-21 </span>
          </p>
          <p>
            or email us at{" "}
            <span className="blue">
              <a href="mailto:customercare@eliterewards.biz">
                <strong>customercare@eliterewards.biz</strong>
              </a>
            </span>
          </p>
          <p> and one of our reward card specialists will get back to you.</p>
          <p>Enjoy your card!</p>
          <p className="note">
            PLEASE NOTE: If the card you have selected is out of stock, we will
            send you a Mastercard Reward Card instead, which can be used at
            thousands of retailers instead of just one place!
          </p>
        </div>
      </div>
      {showPopup === "true" && <ContentPopup />}
      <Footer />
    </>
  );
}
export default Thankyou;
