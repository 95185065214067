import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import registerServiceWorker from "./registerServiceWorker";
import HttpsRedirect from "react-https-redirect";
import { Helmet } from "react-helmet";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>The RoomPlace Sweepstakes</title>
    </Helmet>
    <BrowserRouter>
    {/* <HttpsRedirect> */}
      <App />
    {/* </HttpsRedirect> */}
    </BrowserRouter>
  </>
);
registerServiceWorker();
