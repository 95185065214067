import React from "react";
import bsstsiLogo from "../images/log-12.png";
import PdfViewer from "./PdfViewer";
function Header() {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="translator-holder">
          <div className="logo">
            <img
              src={bsstsiLogo}
              alt="bsstsi-logo.png"
              className="img-responsive"
            />
          <PdfViewer />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Header;
