import React from "react";
import "./PdfViewer.css";
export default function PdfViewer() {
  return (
    <a
      className="pdf"
      href="https://theroomplacesweepstakes.com/assets/disclaimer.pdf"
      target="_blank"
    >
      Legal Disclosure
    </a>
  );
}
